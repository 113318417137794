<div class="child-modal" style="border:1px solid black">
    <div class="modal-header" >
        <header-title class="w-100" [headerTitle]="formBool? 'Edit preset': 'Add preset' ">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </header-title>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="isSavingData"></mat-progress-bar>
    <form [formGroup]="addPresetNameForm" (ngSubmit)="submitAddDevice(addPresetNameForm.value)">
        <perfect-scrollbar
            [config]="{ useBothWheelAxes: false, suppressScrollX: true, suppressScrollY: false, wheelPropagation: true }">
            <div class="modal-body modal_height_contact">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <p>Click <span class="text-success">'{{formBool?'Edit':'Save'}}'</span> to {{formBool?'update a preset':'create a new preset'}}.</p>
                    </div>
                </div>
                <div class="row">
                      <div class="col-lg-12 col-md-12 mb-1">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Enter Preset Name</mat-label>
                          <input matInput required formControlName="presetName" placeholder="Enter Preset Name" type="text" />
                        </mat-form-field>
                      
                        <show-form-error [controlName]="addPresetNameForm.controls['presetName']"></show-form-error>
                        
                        <!-- Custom error messages -->
                        <div *ngIf="addPresetNameForm.get('presetName')?.hasError('whitespace')" class="error-message">
                          Preset name cannot contain only blank spaces.
                        </div>
                        <div *ngIf="addPresetNameForm.get('presetName')?.hasError('maxlength')" class="error-message">
                          Preset name cannot exceed 50 characters.
                        </div>
                      </div>
                      
                      
                </div>
               
            </div>
        </perfect-scrollbar>
        <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">
                <i class="ft-x"></i> Discard
            </button>
            <button type="submit" class="btn btn-success">
                <i class="ft-check-circle"></i> {{ "Save" }}
            </button>
        </div> -->
        <div class="modal-footer d-flex justify-content-end">
            <!-- New button on the left side -->
            <!-- <button type="button" class="btn btn-primary">
                 View
            </button> -->
            
            <!-- Buttons on the right side -->
            <div>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">
                    <i class="ft-x"></i> Discard
                </button>
                <button [disabled]="!addPresetNameForm.valid" type="submit" class="btn btn-success">
                    <i class="ft-check-circle"></i> {{ formBool? 'Edit' : 'Save' }}
                </button>
            </div>
        </div>
        
    </form>
</div>