import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl,ValidationErrors,ValidatorFn  } from '@angular/forms';
import { BaseService } from 'app/@core/services/base.service';
import { FormValidatorService } from 'app/@core/services/formValidator.service';
import { StorageService } from 'app/@core/services/storage.service';
import { UtilitiesService } from 'app/@core/services/utilities.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'preset-name-action',
  templateUrl: './preset-name-action.component.html',
  styleUrls: ['./preset-name-action.component.scss']
})
export class PresetNameActionComponent implements OnInit {
  @Output() closeModel = new EventEmitter<string>();
  @Output() presetNameForm = new EventEmitter<any>();
  // @Output() editPresettableFilterId = new EventEmitter<number>();
  @Input() formData;
  @Input() formBool;
  isSavingData = false;
  editPresetId:any;

  addDeviceModal?: BsModalRef;
  addPresetNameForm: FormGroup;
  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    public utlititiesService: UtilitiesService,
    public requestService: BaseService,
    public storageService: StorageService,
    private toastr: ToastrService,
    private formValidatorService: FormValidatorService,
  ) { }

  ngOnInit(): void {
    this.initZoneForm();
  }
  ngOnChanges(changes: SimpleChanges) {
     
    if (changes.formData && changes.formData.currentValue) {
      const currentValue = changes.formData.currentValue;
      this.addPresetNameForm.patchValue(currentValue);
      this.editPresetId=changes.formData.currentValue.tableId
    }
  }
  initZoneForm() {
    this.addPresetNameForm = this.formBuilder.group({
      presetName: [null, [Validators.required, this.noOnlyWhitespaceValidator(), Validators.maxLength(50)]],
    });
  }
  noOnlyWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = control.value && control.value.trim() !== '' && /[^\s]/.test(control.value);
      return isValid ? null : { whitespace: true };
    };
  }
  closeModal(textMessage = '') {
    this.closeModel.emit(textMessage);
  }

  submitAddDevice(saveObj) {
      
    //  this.editPresettableFilterId.emit(this.editPresetId);
    this.presetNameForm.emit({saveObj , editPresetId: this.editPresetId})
  }
}
