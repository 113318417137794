<div class="">
  <div class="modal-header">
    <header-title class="w-100" [headerTitle]="'Edit Columns'">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModalButton()">
        <span aria-hidden="true">&times;</span>
      </button>
    </header-title>
  </div>

  <div class="modal-body">
    <div class="col-lg-12 col-md-12 col-sm-12 mb-3 list-group column-list"> 
    <div class="btn-group w-100" dropdown #dropdown="bs-dropdown" [autoClose]="true">
         <button
        id="button-basic" 
        dropdownToggle 
        type="button" 
        class="btn btn-primary dropdown-toggle" 
        aria-controls="dropdown-basic">
        {{ this.defaultPresetsName}} <span class="caret"></span>
        </button>

      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu w-100 set-heightdrpdown" cdkScrollable role="menu" aria-labelledby="button-basic">
        <div class="col-md-12 mt-2">
          <p class="text-light">Company Preset</p>
          <div class="col-md-12 d-flex justify-content-between mb-2" *ngFor="let preset of presetNameArrayDefault">
            <label class="c-pointer company-preset" for="presetName">{{preset.presetName}}</label>
            <div class="d-flex align-items-center" >
              <span class="text-light mr-1">{{preset.isDefault===true?'Current':'Default'}}</span>
            <input type="checkbox" id="presetName" (change)="onCheckboxChange($event.target)" [checked]="preset.isDefault">
            </div>
          </div>
        </div>
        <li class="divider dropdown-divider"></li>
        <div class="col-md-12 mt-2">
          <p class="text-light">User Preset</p>
          <div *ngIf="presetNameArray.length === 0" class="col-md-12 col-sm-12">
          <div class="col-md-12 col-sm-12 text-center mt-2 mb-4">
            <p>There is no user preset.</p>
            </div>
          </div>
          <div *ngFor="let preset of presetNameArray" class="col-md-12 d-flex justify-content-between mb-2">
              <label class="c-pointer" [for]="preset.presetName">{{ preset.presetName }}</label>
            <div class="d-flex align-items-center">
            <span *ngIf="preset.isDefault" class="text-light mr-1">Current</span>
            <i *ngIf="preset.tableFilterId !== 0 && preset.isDefault" 
            (click)="editPreset(preset,presetModalTemplate)" 
            class="c-pointer text-primary fas fa-edit mr-1">
          </i>
            <i *ngIf="preset.tableFilterId!==0 && preset.isDefault" (click)="deletePreset(preset.tableFilterId)" class="c-pointer text-danger fa fa-times mr-1"></i>
            <input 
              [id]="preset.presetName" 
              type="checkbox" 
              (change)="onCheckboxChange($event.target, preset.presetName, preset.isDefault, preset.tableFilterId)"
              [checked]="preset.isDefault"
            >
          </div>
          </div>
          
          
        </div>
      </ul>
    </div>
  </div>

    
    <div class="set-height" cdkScrollable>
      <ul cdkDropList class="list-group column-list  example-boundary" (cdkDropListDropped)="drop($event)">
        <li class="list-group-item">
          <div class="custom-control custom-checkbox">
            <input (change)="selectAll($event)" [checked]="isAllSelected" type="checkbox" checked="" name="customCheck"
              id="select-all" class="custom-control-input">
            <label for="select-all" class="custom-control-label">
              <span></span>
              <p title="Select All" class="text-truncate mb-0 font-weight-bold">
                Select All
              </p>
            </label>
          </div>
        </li>
        <li class="list-group-item" *ngFor="let columnItem of menuColumns;let i=index" cdkDrag
          cdkDragBoundary=".example-boundary">
          <span class="example-custom-placeholder" *cdkDragPlaceholder></span>
          <div class="d-flex justify-content-between">
            <div class="custom-checkbox custom-control">
              <input (change)="toggleColumn(columnItem)" [checked]="columnItem.isShowColumn" type="checkbox" checked=""
                name="customCheck" [id]="'item'+i" class="custom-control-input">
              <label [for]="'item'+i" class="custom-control-label">
                <p [title]="columnItem.title || columnItem.label || columnItem.field"
                  class="text-truncate mb-0 font-weight-bold">
                  {{ columnItem.label || columnItem.field }}</p>
              </label>
            </div>
            <div class="advanceData">
              <button *ngIf="columnItem?.advanceData"
                [ngClass]="columnItem?.advanceDataClass">{{columnItem?.advanceData}}</button>
            </div>
            <div class="example-handle" cdkDragHandle>
              <span>
                <i class="ft-move "></i>
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="border-top  mt-0 pt-1">
    <button title="Cancel Changes" type="button" class="btn btn-sm btn-danger text-right ml-1" data-dismiss="modal"
      (click)="closeModalButton()">
      <i class="ft-x"></i> Cancel
    </button>
    <div class="d-flex float-right mb-2 mr-2">
      <button [disabled]="isRestoring" title="Restore to default settings" class="btn btn-sm btn-secondary mr-1"
        (click)="restore($event.target)" title="Restore to Default">
        <i class="ft-refresh-cw"></i>
        Restore
      </button>
      <button type="button" class="btn btn-primary mr-1" [disabled]="!isChangedAnyThing" (click)="applyColumn(true)">
        <i class="fas fa-eye"></i>
        View
      </button>
      <button class="btn btn-sm btn-success" [disabled]="!isChangedAnyThing"  (click)="presetNameModal(presetModalTemplate)" title="Save custom settings">
        <i class="ft-check-circle"></i>
        {{formBool ? 'Edit' : 'Add'}}
      </button>
    </div>
  </div>
</div>

<ng-template #presetModalTemplate>
  <preset-name-action (closeModel)="closeModalPreset($event)"(presetNameForm)="presetNameForm($event)" [formData]="formData" [formBool]=" formBool"></preset-name-action>
</ng-template>