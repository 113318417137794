import { environment } from './../../../environments/environment';
import { Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { StorageService } from "app/@core/services/storage.service";
import { saveAs } from 'file-saver';

@Injectable()
export class BaseService {
    presetNameArr:any=[];
    presetNameArrDefault:any=[];
    gettingResponse:any =null;
    private readonly STORAGE_KEY = 'table-settings';
    reloadCoulmn = new Subject();
    gridCount = new Subject();
    tableData = new Subject();
    tableData$ = this.tableData.asObservable();
    public filterSelect2Options = [];




    public createE4ScoreRoutePath(parentPath: string, completePath: string): any {
        const moduleId = localStorage.getItem('moduleId');
        const subscriptionId = localStorage.getItem('subscriptionId');
        const userType = localStorage.getItem("userType");

        if (userType === "Company User") {
            const companyUserId = localStorage.getItem("companyUserId");
            return this.getRequest(`accessspecification/GetCompanyUserAccessSpecificationsByModuleId?UserId=${companyUserId}&ModuleId=${moduleId}`).pipe(
                map(res => {
                    const functionList = res.data;
                    const ezTrackModule = functionList?.find(module => module.moduleId == moduleId);
                    const ezTrackParent: any = ezTrackModule?.parents;

                    const parentFeature = ezTrackParent?.find(parent => parent.featurePath === parentPath);
                    const features: any = parentFeature?.features;
                    const featureFuncs = features?.find((path) => path.featurePath == completePath);
                    const funcs = featureFuncs?.functions;

                    return funcs || [];
                })
            );
        } else {
            return this.getRequest(`accessspecification/GetAccessSpecificationsBySubscriptionId?SubscriptionId=${subscriptionId}`).pipe(
                map(res => {
                    const functionList = res.data;
                    const ezTrackModule = functionList?.find(module => module.moduleId == moduleId);
                    const ezTrackParent: any = ezTrackModule?.parents;
                    const parentFeature = ezTrackParent?.find(parent => parent.featurePath === parentPath);
                    const features: any = parentFeature?.features;
                    const featureFuncs = features?.find((path) => path.featurePath == completePath);
                    const funcs = featureFuncs?.functions;
                    return funcs || [];
                })
            );
        }
    }

    setData(data: string) {
        this.tableData.next(data);
    }

    getTableData(): Observable<any> {
        return this.tableData.asObservable();
    }

    constructor(
        private http: HttpClient,
        private spinner: NgxSpinnerService,
        public storageService: StorageService

    ) { }

    public sendForgotPassword(url): Observable<any> {
        return this.http.post(environment.APIURL + url, {});
    }

    public postRequest(url, body): Observable<any> {

        const tableSettingKey = localStorage.getItem('tableSettingKey');
        let isLoginCall = url.includes('login')
        if (!isLoginCall) {
            let parseData = JSON.stringify(body);
            localStorage.setItem(tableSettingKey, parseData);
        }
        return this.http.post(environment.APIURL + url, body);
    }

    public getRequest(URL: any): Observable<any> {
        return this.http.get(environment.APIURL + URL);
    }

    public putRequest(url, body): Observable<any> {
        return this.http.put(environment.APIURL + url, body);
    }

    public removeRequest(url, UserId?): Observable<any> {
        
        return this.http.delete(environment.APIURL + url);
    }
   
    

    public exportExcel(URL: any, postData): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(environment.APIURL + URL, postData, { headers, responseType: 'blob' })
    }

    public importExcel(URL: any, postData): Observable<any> {
        return this.http.post(environment.APIURL + URL, postData)
    }

    public getMapData(PageNumber, assetType = 0, assetList, optionalPageSize): Observable<any> {
        const companyId = this.storageService.getCompanyID();
        const url = `MapData`
        let obj = {
            "companyId": companyId,
            "assetType": assetType,
            "pageNumber": PageNumber,
            "pageSizeOptional": optionalPageSize,
            "assetList": assetList
        }
        return this.http.post(environment.APIURL + url, obj);
    }

    userId = localStorage.getItem('companyUserId');

    public getTableSettings1(pageReference): Observable<any> {
         
        const url = `filter?userId=${this.userId}&page=${pageReference}`;
        return this.http.get(environment.APIURL + url);
    }

    getTableSettings(pageReference, isHardReload): Observable<any> {
        const tableSettingKey = localStorage.getItem('tableSettingKey');
        var isViewBool:any = localStorage.getItem("isViewBool");
        if(isViewBool===null) {
           isViewBool=false; 
        }
        
        
        
        // isViewBool == null ? false : true;
        let jsonColumnData:any = localStorage.getItem("jsonColumnData");
         
        if(isViewBool && isViewBool!=="null"){
            let parseData = JSON.parse(isViewBool);
            var isView=parseData
          }
           
          if(jsonColumnData && jsonColumnData!=="null"){
            let parseData =JSON.parse(jsonColumnData);
            var jsonColumnDataGet=parseData
          }
           
        const cachedData = localStorage.getItem(pageReference + '-' + this.STORAGE_KEY);
        let userType = localStorage.getItem("userType");
        let userId: any;
        if (userType === "Company User") {
            userId = localStorage.getItem("companyUserId");
        }
        else {
            userId = null;
        }
        const companyId = localStorage.getItem('companyID')
        if (cachedData && isHardReload) {
            return of(JSON.parse(cachedData));
        } else {
            let obj = {
                "companyId": companyId,
                "userId": userId,
                "page": pageReference,
                "isView": isView,
                "json": jsonColumnDataGet
            }
            const url = 'GetFilter'; //`filter?userId=${userId}&page=${pageReference}&companyId=${companyId}&isView=${isView}&jsonColumnDataGet=${jsonColumnDataGet}`;
            return this.http.post(environment.APIURL + url,obj)
                .pipe(
                    map((response: any) => {
                        var companyNamelocalStorage=localStorage.getItem("companyName")
                        // if(companyNamelocalStorage && companyNamelocalStorage!=="null"){
                        //     var companyName=JSON.parse(companyNamelocalStorage);
                        // }
                        localStorage.setItem(pageReference + '-' + this.STORAGE_KEY, JSON.stringify(response));
                        
                        switch (pageReference) {
                            case "TrackedAssets":
                                var pageReferenceName = 'Tracked Assets';
                                break;
                            case "assetPools":
                                var pageReferenceName = 'Asset Pools';
                                break;
                            case "DwellTime":
                                var pageReferenceName = 'Dwell Time';
                                break;
                            case "TrackHistory":
                                var pageReferenceName = 'Tracked History';
                                break;
                            case "Partner":
                                var pageReferenceName = 'Partners';
                                break;
                            case "Locations":
                                var pageReferenceName = 'Locations';
                                break;
                            case "AssetTypes":
                                var pageReferenceName = 'Asset Types';
                                break;
                            case "AssetDevice":
                                var pageReferenceName = 'Installed Devices';
                                break;
                            case "Asset":
                                var pageReferenceName = 'Assets';
                                break;
                            case "Device":
                                var pageReferenceName = 'Devices';
                                break;
                            case "CompanyEmployee":
                                var pageReferenceName = 'User Profiles';
                                break;
                            case "CompanyRole":
                                var pageReferenceName = 'Roles';
                                break;
                            case "CompanyUser":
                                var pageReferenceName = 'Logins';
                                break;
                            case "CompanyOperatingUnit":
                                var pageReferenceName = 'Operating Unit';
                                break;
                            case "CompanyDivision":
                                var pageReferenceName = 'Division';
                                break;
                            case "CompanyDesignation":
                                var pageReferenceName = 'Designation';
                                break;
                            case "AssetEventHistory":
                                var pageReferenceName = 'Asset History';
                                break;
                            case "DeviceEventHistory":
                                var pageReferenceName = 'Device History';
                                break;
                                default:
                                var pageReferenceName = 'current page';
                        

                        }
                        if(response.isDefault===true || response.isDefault===false){
                            this.presetNameArrDefault=[]
                             
                            let obj1={
                                presetName:pageReferenceName +  ' - '  +companyNamelocalStorage,
                                isDefault:response.isDefault

                            }
                            this.presetNameArrDefault.push(obj1)
                            localStorage.setItem('presetNameDefault', JSON.stringify(this.presetNameArrDefault));
                        }
                        
                            this.presetNameArr = []; // Define arr outside the map loop
                            response.tablePresetResponses.map(x => {
                                let obj = { // Define obj inside the map loop
                                    presetName: x.presetName,
                                    tableFilterId:x.tableFilterId,
                                    isDefault:x.isDefault
                                };
                                this.presetNameArr.push(obj);
                                
                                x.isDefault// Push obj to arr within the map loop

                            });
                            localStorage.setItem('presetName', JSON.stringify(this.presetNameArr));

                            console.log(this.presetNameArr); // Now arr will have the list of preset objects
                        // }
                        this.gettingResponse=null;
                         
                        if(response.isDefault===true){
                            var presetName = "system preset";
                            localStorage.setItem("responsePresetName", JSON.stringify(presetName));

                            // var parseData = JSON.parse(response.filterJson)
                            var parseData = response.filterJson ? JSON.parse(response.filterJson) :'';

                            // parseData.presetName = presetName
                            localStorage.setItem(pageReference + "-filterJSON", JSON.stringify(parseData));
                             
                            this.gettingResponse= response;

                        }
                        else{
                            response.tablePresetResponses.map(x=>{
                                 
                                if (x.isDefault === true) {
                                     
                                    var presetName = x.presetName;
                                    localStorage.setItem("responsePresetName", JSON.stringify(presetName));
                                    // var parseData = JSON.parse(x.filterJson); // Parse the JSON string if it's a string
                                    var parseData = x.filterJson ? JSON.parse(x.filterJson) :'';

                                    // parseData.presetName = presetName; // Add presetName to the filterJson object
                                
                                    // Convert back to a JSON string and set in localStorage
                                    localStorage.setItem(pageReference + "-filterJSON", JSON.stringify(parseData));
                                   this.gettingResponse= x;

                                }
                            })
                        }
                        localStorage.setItem("isViewBool",JSON.stringify(false));
                        return this.gettingResponse
                        // return response;
                    }),
                    catchError(error => of(error))
                );
        }
    }

    public postTableSettings(tableSettingKey, body): Observable<any> {
         
        localStorage.removeItem(tableSettingKey + '-' + this.STORAGE_KEY);
        return this.http.post(environment.APIURL + 'filter', body);
    }
    public enablePreset(body): Observable<any> {
         
        // localStorage.removeItem(tableSettingKey + '-' + this.STORAGE_KEY);
        return this.http.post(environment.APIURL + 'tablefilter/enablepreset', body);
    }
    public checkPresetName(body): Observable<any> {
         
        // localStorage.removeItem(tableSettingKey + '-' + this.STORAGE_KEY);
        return this.http.post(environment.APIURL + 'tablefilter/checkpresetname', body);
    }

    // public enablePreset(url, body): Observable<any> {
    //     return this.http.post(environment.APIURL + url, body);
    //   }

    public getLocalTableSettings(tableSettingKey): string {
        return localStorage.getItem(tableSettingKey + '-' + this.STORAGE_KEY);
    }

    public showLoader() {
        this.spinner.show();
    }
    public hideLoader() {
        this.spinner.hide();
    }

    filterDeviceModel(value: string, optionsArr): any[] {
        if (!value) {
            return optionsArr;
        }
        const lowerCaseValue = value.toLowerCase();
        return optionsArr.filter(option => option.deviceType.toLowerCase().includes(lowerCaseValue));
    }

    filterOptions(value: string, optionsArr): any[] {
        if (!value) {
            return optionsArr;
        }
        const lowerCaseValue = value.toLowerCase();
        return optionsArr.filter(option => option.name.toLowerCase().includes(lowerCaseValue));
    }

    filterOption(value: string, optionsArr): any[] {
        if (!value) {
            return optionsArr;
        }
        const stringValue = value.toLowerCase();
        return optionsArr.filter(option => option.imei.toLowerCase().includes(stringValue));
    }

    downloadTemplate(fileUrl, savedName) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', fileUrl, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (xhr.status === 200) {
                const blob = new Blob([xhr.response], { type: 'application/octet-stream' });
                saveAs(blob, savedName);
            }
        };
        xhr.send();
    }
}